<template>
  <b10-base>
    <!-- datos estáticos de material de sistema -->
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <v-text-field
        v-model="form.descripcion"
        clearable
        label="Descripción"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="unidades"
      :rules="formRules.unidades"
    >
      <v-text-field
        v-model.number="form.unidades"
        label="Unidades"
        clearable
        type="number"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="fsistema"
      :rules="formRules.fsistema"
    >
      <b10-date-picker
        v-model="form.fsistema"
        title="Instalación"
        :error-messages="errors"
        @change="changeFsistema"
      />
    </ValidationProvider>
    <b10-autocomplete
      v-model="form.idtimportancia_material"
      :items="tipoImportanciaMaterial"
      item-value="idtimportancia_material"
      item-text="descripcion"
      label="Importancia"
      clearable
    />
    <v-text-field
      v-if="form.ver_mat_nserie"
      v-model="form.nserie"
      clearable
      label="Nº serie"
      :append-outer-icon="$vuetify.icons.values.camera"
      @click:append-outer="clickNserieQR"
    />
    <v-text-field
      v-if="form.ver_mat_nelemento"
      v-model="form.nelemento"
      clearable
      label="Nº elemento"
    />
    <v-text-field
      v-if="form.ver_mat_ubicacion"
      v-model="form.ubicacion"
      clearable
      label="Ubicación"
    />
    <v-text-field
      v-if="form.ver_mat_netiqueta"
      v-model="form.netiqueta"
      clearable
      label="Nº etiqueta"
      :append-outer-icon="$vuetify.icons.values.camera"
      @click:append-outer="clickNetiquetaQR"
    />
    <v-text-field
      v-if="form.ver_mat_num_zona"
      v-model="form.num_zona"
      clearable
      label="Nº zona"
    />
    <v-text-field
      v-if="form.ver_mat_evento"
      v-model="form.evento"
      clearable
      label="Evento"
    />
    <v-text-field
      v-if="form.ver_mat_funcion"
      v-model="form.funcion"
      clearable
      label="Función"
    />
    <v-text-field
      v-if="form.ver_mat_particion"
      v-model="form.particion"
      clearable
      label="Partición"
    />
    <!-- fultrevision (no debe ser editable) -->
    <b10-date-picker
      v-if="form.ver_mat_fult_retimbrado"
      v-model="form.fult_retimbrado"
      title="Último retimbrado"
    />
    <!-- fprox_retimbrado ( es un campo calculado en el servidor) -->
    <b10-date-picker
      v-if="form.ver_mat_ffabricacion"
      v-model="form.ffabricacion"
      title="Fabricación"
      @change="changeFfabricacion"
    />
    <b10-date-picker
      v-if="form.ver_mat_fcaducidad"
      v-model="form.fcaducidad"
      title="Caducidad"
    />
    <b10-date-picker
      v-if="form.ver_mat_ffin_garantia"
      v-model="form.ffin_garantia"
      title="Fin de garantía"
    />
    <b10-autocomplete
      v-if="form.ver_mat_idfabricante"
      v-model="form.idfabricante"
      :items="fabricantes"
      item-value="idfabricante"
      item-text="descripcion"
      label="Fabricante"
      clearable
    />
    <b10-autocomplete
      v-if="form.ver_mat_idagente_extintor"
      v-model="form.idagente_extintor"
      :items="agentesExtintores"
      item-value="idagente_extintor"
      item-text="descripcion"
      label="Agente extintor"
      clearable
    />
    <b10-autocomplete
      v-if="form.ver_mat_idagente_propelente"
      v-model="form.idagente_propelente"
      :items="agentesPropelentes"
      item-value="idagente_propelente"
      item-text="descripcion"
      label="Agente propelente"
      clearable
    />
    <v-text-field
      v-if="form.ver_mat_volumen"
      v-model.number="form.volumen"
      clearable
      label="Volumen"
      type="Number"
    />
    <v-text-field
      v-if="form.ver_mat_peso_total"
      v-model.number="form.peso_total"
      clearable
      label="Peso total"
      type="Number"
    />
    <v-text-field
      v-if="form.ver_mat_peso_agente_extintor"
      v-model.number="form.peso_agente_extintor"
      clearable
      label="Peso agente extintor"
      type="Number"
    />
    <v-text-field
      v-if="form.ver_mat_eficacia"
      v-model="form.eficacia"
      clearable
      label="Eficacia"
    />
    <v-text-field
      v-if="form.ver_mat_presion"
      v-model.number="form.presion"
      clearable
      label="Presión"
      type="Number"
    />
    <!-- datos dinámicos de ficha técnica -> TDATO_ARTICULO -->
    <div
      v-for="item in form.datosArticulo"
      :key="item.idtdato_articulo"
    >
      <b10-autocomplete
        v-if="item.tipo_valor === tiposTdatoArticulo.tipos.seleccion"
        v-model="item.valor"
        :items="item.valores"
        item-value="valor"
        item-text="valor"
        :label="item.descripcion"
        clearable
      />
      <v-text-field
        v-if="item.tipo_valor === tiposTdatoArticulo.tipos.numero || item.tipo_valor === tiposTdatoArticulo.tipos.texto"
        v-model="item.valor"
        clearable
        :label="item.descripcion"
      />
      <b10-date-picker
        v-if="item.tipo_valor === tiposTdatoArticulo.tipos.fecha"
        v-model="item.valor"
        clearable
        :title="item.descripcion"
      />
    </div>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <b10-textarea
      v-model="form.observaciones_internas"
      label="Observaciones internas"
    />
    <b10-qr-reader
      :showing.sync="showingQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import _ from '@/utils/lodash'
import Data from './MaterialSistemaFormData'
import { TDATO_ARTICULO } from '@/utils/consts'
import { toDate, addMonths, firstDayOfMonth, isValidDate } from '@/utils/date'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        descripcion: null,
        unidades: null,
        fsistema: null,
        nserie: null,
        nelemento: null,
        ubicacion: null,
        netiqueta: null,
        num_zona: null,
        evento: null,
        funcion: null,
        particion: null,
        idfabricante: null,
        ffabricacion: null,
        idagente_extintor: null,
        idagente_propelente: null,
        volumen: null,
        peso_total: null,
        peso_agente_extintor: null,
        eficacia: null,
        presion: null,
        // fultrevision: null, (no debe ser editable)
        fult_retimbrado: null,
        // fprox_retimbrado: null, (no debe ser editable)
        fcaducidad: null,
        ffin_garantia: null,
        idtimportancia_material: null,
        datosArticulo: [],
        observaciones: null,
        observaciones_internas: null,
      },
      fabricantes: [],
      agentesExtintores: [],
      agentesPropelentes: [],
      tipoImportanciaMaterial: [],
      tiposTdatoArticulo: TDATO_ARTICULO,
      formRules: {
        descripcion: { required: true },
        unidades: { required: true },
        fsistema: { required: true },
      },
      showingQR: false,
      targetFieldQR: '',
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.fabricantes = resp.data.selectFabricante.result.dataset
    this.agentesExtintores = resp.data.selectAgenteExtintor.result.dataset
    this.agentesPropelentes = resp.data.selectAgentePropelente.result.dataset
    this.tipoImportanciaMaterial = resp.data.selectTimportanciaMaterial.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
      if (formData.idficha_tecnica) {
        // Puede que los datos de la ficha técnica estén rellenos o no, así que tengo que traer el tdato_articulo y dato_material_sistema
        const resp = await Data.selectDatoMaterialSistema(this, formData.idmaterial_sistema, formData.idficha_tecnica)
        // recuperar valores de DATO_MATERIAL_SISTEMA
        // hago lo mismo que src/pages/offline/components/MaterialSistemaForm.vue
        const datosArticulo = []
        for (const tdato of resp.data.selectTdatoArticulo.result.dataset) {
          const datoMaterialSistema = _.find(resp.data.selectDatoMaterialSistema.result.dataset, {'idtdato_articulo': tdato.idtdato_articulo})
          const indexDatoArticulo = datosArticulo.push({
            descripcion: tdato.descripcion,
            idtdato_articulo: tdato.idtdato_articulo,
            tipo_valor: tdato.tipo_valor,
            valor: (
              datoMaterialSistema ? datoMaterialSistema.valor : ''
            ),
            iddato_material_sistema: (
              datoMaterialSistema ? datoMaterialSistema.iddato_material_sistema : null
            ),
            valores: []
          })
          // recuperar valores de TDATO_ARTICULO_VALOR (si es un select)
          if (tdato.tipo_valor === TDATO_ARTICULO.tipos.seleccion) {
            // TODO: intentar optimizar esto para que no haga tantas llamadas a la API
            const tdatoArticuloValor = await Data.selectTDatoArticuloValor(
              this, tdato.idtdato_articulo
            )
            datosArticulo[indexDatoArticulo - 1].valores.push(
              ..._.map(tdatoArticuloValor, 'valor')
            )
          }
          // si es date y no tiene formato correcto almacenado
          if (
            tdato.tipo_valor === TDATO_ARTICULO.tipos.fecha &&
            datosArticulo[indexDatoArticulo - 1].valor
          ) {
            const valorDate = toDate(datosArticulo[indexDatoArticulo - 1].valor)
            // si no es un día válido (Invalid Date)
            if (!isValidDate(valorDate)) {
              datosArticulo[indexDatoArticulo - 1].tipo_valor = TDATO_ARTICULO.tipos.texto
            }
          }
        }
        this.$set(this.form, 'datosArticulo', datosArticulo)
      }
    },
    changeFfabricacion (value) {
      if (this.form && this.form.meses_caducidad > 0) {
        this.$set(this.form, 'fcaducidad', addMonths(firstDayOfMonth(toDate(value)), this.form.meses_caducidad))
      }
    },
    changeFsistema (value) {
      if (this.form && this.form.meses_garantia > 0) {
        this.$set(this.form, 'ffin_garantia', addMonths(toDate(value), this.form.meses_garantia))
      }
    },
    clickNetiquetaQR () {
      this.targetFieldQR = 'netiqueta'
      this.showingQR = !this.showingQR
    },
    clickNserieQR () {
      this.targetFieldQR = 'nserie'
      this.showingQR = !this.showingQR
    },
    async decodeQR (value) {
      this.$set(this.form, this.targetFieldQR, value)
    },
  },
}
</script>
